<script>
import aclModuleClient from '@/core/acl-module-client';
import Multiselect from 'vue-multiselect';

export default {
  name: 'AclUserRoles',
  components: { Multiselect },
  data: () => ({
    userUUID: null,
    user: {
      loading: true,
      data: { },
    },
    applications: [],
    categories: [],
    regions: [],
    userRoles: {
      loading: true,
      data: [],
      fields: [
        { key: 'role_id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'group', label: 'Group', sortable: true },
        { key: 'application', label: 'Application', sortable: true },
        { key: 'region', label: 'Region', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      total: 0,
      items: [],
      filters: {
        page: 1,
        pageSize: 15,
        roles: [],
        application: null,
        region: null,
      },
      sort: {
        sortBy: '',
        sortDesc: false,
      },
      applyFilterBtn: {
        loading: false,
      },
    },
    availableRoles: {
      loading: true,
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'application', label: 'Application', sortable: true },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'selected', label: 'Select' },
      ],
      total: 0,
      paginatedItems: new Map(),
      filters: {
        page: 1,
        pageSize: 15,
        name: '',
        application: null,
        category: null,
      },
      sort: {
        sortBy: '',
        sortDesc: false,
        keyMap: {
          id: 'id',
          name: 'title',
          application: 'app_id',
          category: 'category_id',
        },
      },
      applyFilterBtn: {
        loading: false,
      },
      saveBtn: {
        loading: false,
      },
      confirmRolesBtn: {
        loading: false,
      },
    },
    selectedRolesModal: {
      loading: false,
      table: {
        loading: false,
        fields: [
          { key: 'id', label: 'ID' },
          { key: 'name', label: 'Name' },
          { key: 'application', label: 'Application' },
          { key: 'category', label: 'Category' },
          { key: 'regions', label: 'Regions' },
        ],
        items: [],
      },
    },
    removeRoleModal: {
      role: {},
      confirmBtn: {
        loading: false,
      },
    },
  }),
  beforeMount() {
    this.userUUID = this.$route.params.user_uuid;

    return Promise.all([
      this.fetchUser(),
      this.fetchApplications(),
      this.fetchCategories(),
      this.fetchRegions(),
      this.fetchUserRoles(),
      this.changeAvailableRolesPage(this.availableRoles.filters.page),
    ]);
  },
  computed: {
    isAllSelected: {
      get() {
        return this.availableRoles.paginatedItems.get(this.availableRoles.filters.page)
          .every(role => role.selected);
      },
      set(value) {
        return value;
      },
    },
    userName() {
      return this.user.data?.name ?? 'Unknown user';
    },
    availableRolesApplicationFiltersOptions() {
      return [
        { text: 'All applications', value: null },
        ...this.applications
          .map(app => ({ text: app.name, value: app.id }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      ];
    },
    availableRolesCategoriesFiltersOptions() {
      return [
        { text: 'All categories', value: null },
        ...this.categories
          .map(app => ({ text: app.name, value: app.id }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      ];
    },
    userRolesFiltersOptions() {
      const uniqueRoles = new Map();
      this.userRoles.data.forEach(usrRole => {
        uniqueRoles.set(usrRole.title, {
          text: usrRole.title,
          value: usrRole.role_id,
        });
      });

      return Array.from(uniqueRoles.values()).sort((a, b) => a.text.localeCompare(b.text));
    },
    userRolesApplicationFiltersOptions() {
      const uniqueApps = new Map();
      this.userRoles.data.forEach(usrRole => {
        uniqueApps.set(`${usrRole.app_name}-${usrRole.app_id}`, {
          text: usrRole.app_name,
          value: usrRole.app_id,
        });
      });

      return [
        { text: 'All applications', value: null },
        ...Array.from(uniqueApps.values()).sort((a, b) => a.text.localeCompare(b.text)),
      ];
    },
    userRolesRegionFiltersOptions() {
      const uniqueRegions = new Map();
      this.userRoles.data.forEach(usrRole => {
        uniqueRegions.set(`${usrRole.region_code}-${usrRole.region_id}`, {
          text: usrRole.region_code,
          value: usrRole.region_id,
        });
      });

      return [
        { text: 'All regions', value: null },
        ...Array.from(uniqueRegions.values()).sort((a, b) => a.text.localeCompare(b.text)),
      ];
    },
  },
  methods: {
    fetchUser() {
      this.user.loading = true;
      return aclModuleClient
        .get(`v1/users/${this.userUUID}`)
        .then(response => {
          this.user.data = response.data;
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading user');
        })
        .finally(() => {
          this.user.loading = false;
        });
    },
    fetchApplications() {
      return aclModuleClient
        .get('v1/apps')
        .then(response => { this.applications = response.data.data; })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading applications');
        });
    },
    fetchCategories() {
      return aclModuleClient
        .get('v1/categories')
        .then(response => { this.categories = response.data.data; })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading categories');
        });
    },
    fetchRegions() {
      return aclModuleClient
        .get('v1/regions')
        .then(response => { this.regions = response.data.data; })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available regions');
        });
    },
    fetchUserRoles() {
      this.userRoles.loading = true;
      return aclModuleClient
        .get(`v1/users/${this.userUUID}/roles`)
        .then(userRoles => {
          this.userRoles.data = userRoles.data;
          this.userRoles.total = userRoles.data.length;
          return this.handleApplyUserRolesFilters();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading user roles');
        })
        .finally(() => {
          this.userRoles.loading = false;
        });
    },
    fetchAvailableRoles() {
      const { filters, sort } = this.availableRoles;
      let search = [];
      if (filters.name) search.push(`title:${filters.name}`);
      if (filters.application) search.push(`app_id:${filters.application}`);
      if (filters.category) search.push(`category_id:${filters.category}`);
      search = search.join(',');

      const sortKey = sort.keyMap[sort.sortBy] || sort.sortBy;
      const params = {
        page: filters.page,
        limit: filters.pageSize,
        sort: sortKey ? `${sortKey}:${sort.sortDesc ? 'desc' : 'asc'}` : null,
      };

      if (search !== '') {
        params.search = search;
      }

      return aclModuleClient
        .get('v1/roles', { params })
        .then(({ data: { data: items, total } }) => {
          const formattedItems = items.map(role => ({
            id: role.id,
            name: role.title,
            application: role.app_name,
            category: role.category_name,
            selected: false,
          }));
          this.availableRoles.paginatedItems.set(
            this.availableRoles.filters.page,
            formattedItems,
          );
          this.availableRoles.total = total;
        });
    },
    changeAvailableRolesPage(newPage) {
      this.availableRoles.filters.page = newPage;
      this.availableRoles.loading = true;

      const promise = this.availableRoles.paginatedItems.has(this.availableRoles.filters.page)
        ? Promise.resolve()
        : this.fetchAvailableRoles();

      return promise
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available roles');
        })
        .finally(() => {
          this.availableRoles.loading = false;
        });
    },
    goToUserGroups() {
      this.$router.push(`/acl/users/${this.userUUID}/groups`);
    },
    openRegionsModal() {
      this.availableRoles.saveBtn.loading = true;
      this.selectedRolesModal.loading = true;
      this.selectedRolesModal.table.loading = true;

      let selectedRoles = [];
      this.availableRoles.paginatedItems.values().forEach(page => {
        selectedRoles = page.reduce((selected, role) => (
          role.selected
            ? [ ...selected, { ...role, regions: [] } ]
            : selected
        ), selectedRoles);
      });

      if (!selectedRoles.length) {
        this.$noty.info('You must select at least one role to assign to this user.');
        this.availableRoles.saveBtn.loading = false;
        return;
      }

      this.selectedRolesModal.table.items = selectedRoles;
      this.selectedRolesModal.table.loading = false;
      this.$refs['modal-roles-regions'].show();
      this.availableRoles.saveBtn.loading = false;
      this.selectedRolesModal.loading = false;
    },
    handleRemoveRole(itemToRemove) {
      itemToRemove.loading = true;
      this.removeRoleModal.role = itemToRemove;
      this.$refs['modal-roles-remove'].show();
      itemToRemove.loading = false;
    },
    handleRegionsModalHidden() {
      this.selectedRolesModal.table.items = [];
      this.selectedRolesModal.loading = true;
      this.selectedRolesModal.table.loading = true;
    },
    handleApplyToAllRoles(regionsToApply) {
      this.selectedRolesModal.table.items.forEach(selectedRole => {
        selectedRole.regions = regionsToApply;
      });
    },
    handleApplyAvailableRolesFilters() {
      this.availableRoles.paginatedItems.clear();
      this.availableRoles.filters.page = 1;
      this.changeAvailableRolesPage(this.availableRoles.filters.page);
    },
    handleApplyUserRolesFilters() {
      const roleMatchesFn = (fltr, val) => (!(fltr !== null && fltr !== val));
      this.userRoles.items = this.userRoles.data.reduce((items, userRole) => {
        const isRoleNameFilterMatch = (
          this.userRoles.filters.roles.length === 0
          || this.userRoles.filters.roles.some(role => role.value === userRole.role_id)
        );

        if (
          isRoleNameFilterMatch
          && roleMatchesFn(this.userRoles.filters.application, userRole.app_id)
          && roleMatchesFn(this.userRoles.filters.region, userRole.region_id)
        ) {
          items = [
            ...items,
            {
              role_id: userRole.role_id,
              name: userRole.title,
              group: userRole.group_title,
              application: userRole.app_name,
              region: userRole.region_code,
              region_id: userRole.region_id,
              loading: false,
            },
          ];
        }
        return items;
      }, [ ]);
    },
    handleSaveRoles() {
      const hasWithoutRegions = this.selectedRolesModal.table.items
        .some(role => role.regions.length === 0);

      if (hasWithoutRegions) {
        this.$noty.info('You must select at least one region per role.');
        return Promise.resolve();
      }

      this.availableRoles.applyFilterBtn.loading = true;
      this.availableRoles.saveBtn.loading = true;
      this.availableRoles.confirmRolesBtn.loading = true;

      const modalHideEventHandler = e => { e.preventDefault(); };

      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      return new Promise(res => {
        const userRoles = new Map();
        this.userRoles.data.forEach(userRole => {
          userRoles.set(`${userRole.role_id}-${userRole.region_id}`, {
            role_id: userRole.role_id,
            region_id: userRole.region_id,
          });
        });

        this.selectedRolesModal.table.items.forEach(selectedRole => {
          selectedRole.regions.forEach(region => {
            userRoles.set(`${selectedRole.id}-${region.id}`, {
              role_id: selectedRole.id,
              region_id: region.id,
            });
          });
        });

        res([ ...userRoles.values() ]);
      })
        .then(userRoles => aclModuleClient.put(`v1/users/${this.userUUID}/roles`, userRoles))
        .then(() => {
          this.$noty.success('User roles successfully saved.');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-roles-regions'].hide();

          this.availableRoles.paginatedItems.values().forEach(page => {
            page.forEach(role => { role.selected = false; });
          });

          return this.fetchUserRoles();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to save roles');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          this.availableRoles.applyFilterBtn.loading = false;
          this.availableRoles.saveBtn.loading = false;
          this.availableRoles.confirmRolesBtn.loading = false;
        });
    },
    confirmRemoveRole() {
      this.removeRoleModal.confirmBtn.loading = true;
      this.removeRoleModal.role.loading = true;

      const modalHideEventHandler = e => { e.preventDefault(); };
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      const updatedUserRoles = this.userRoles.data
        .reduce((acc, userRole) => {
          const hasNoGroup = !userRole.group_id;
          const isChosenToDelete = (
            userRole.role_id === this.removeRoleModal.role.role_id
            && userRole.region_id === this.removeRoleModal.role.region_id
          );

          if (hasNoGroup && !isChosenToDelete) {
            return [
              ...acc,
              { role_id: userRole.role_id, region_id: userRole.region_id },
            ];
          }
          return acc;
        }, [ ]);

      return aclModuleClient
        .put(`v1/users/${this.userUUID}/roles`, updatedUserRoles)
        .then(() => {
          this.$noty.success('Role was successfully unassigned from this user.');
          this.userRoles.filters.roles = null;
          this.userRoles.filters.application = null;
          this.userRoles.filters.region = null;
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-roles-remove'].hide();

          return this.fetchUserRoles();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to unassign user\'s role');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          this.removeRoleModal.confirmBtn.loading = false;
          this.removeRoleModal.role.loading = false;
        });
    },
    onUserRolesSortChanged({ sortBy, sortDesc }) {
      this.userRoles.sort.sortBy = sortBy;
      this.userRoles.sort.sortDesc = sortDesc;
    },
    onAvailableUserRolesSortChanged({ sortBy, sortDesc }) {
      this.availableRoles.loading = true;
      this.availableRoles.sort.sortBy = sortBy;
      this.availableRoles.sort.sortDesc = sortDesc;
      this.availableRoles.paginatedItems.clear();
      this.changeAvailableRolesPage(this.availableRoles.filters.page)
        .then(() => {
          this.availableRoles.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available roles');
          this.availableRoles.loading = false;
        });
    },
    selectAllRoles(isAllSelected) {
      this.availableRoles.paginatedItems.get(this.availableRoles.filters.page).forEach(role => {
        role.selected = isAllSelected;
      });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="mt-2 mb-4">
          <h4>
            ACL User Roles -
            <b-spinner v-if="user.loading" class="align-middle"></b-spinner>
            <span v-else>{{ userName }}</span>
          </h4>
        </div>
      </b-col>
    </b-row>

    <b-row cols="1" cols-xl="2">
      <!-- #region User Roles -->
      <b-col class="mb-4">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              User Roles
              <sup
                v-b-tooltip:hover
                title="These are the roles currently assigned to this user.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>

            <div>
              <b-button
                variant="outline-secondary"
                title="Show filters"
                v-b-tooltip:hover
                v-b-toggle.collapse-user-roles-filters>
                Filters
                <feather type="filter"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <b-collapse id="collapse-user-roles-filters">
                  <b-card class="mb-3" bg-variant="light" no-body>
                    <b-card-body>
                      <b-card-title class="">
                        Filters
                      </b-card-title>

                      <b-form @submit.prevent>
                        <b-form-row>
                          <b-col>
                            <b-form-group id="arf-group-name" label="Role" label-for="arf-name">
                              <multiselect
                                placeholder="All roles"
                                label="text"
                                track-by="text"
                                v-model="userRoles.filters.roles"
                                :options="userRolesFiltersOptions"
                                multiple
                                searchable />
                            </b-form-group>
                          </b-col>

                        </b-form-row>
                        <b-form-row>

                          <b-col>
                            <b-form-group id="arf-group-application" label="Application" label-for="arf-application">
                              <b-form-select
                                id="arf-application"
                                v-model="userRoles.filters.application"
                                :options="userRolesApplicationFiltersOptions" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-region" label="Region" label-for="arf-region">
                              <b-form-select
                                id="arf-region"
                                v-model="userRoles.filters.region"
                                :options="userRolesRegionFiltersOptions" />
                            </b-form-group>
                          </b-col>

                        </b-form-row>

                        <b-form-row>
                          <b-col class="d-flex justify-content-end">
                            <b-button
                              prevent
                              type="submit"
                              variant="primary"
                              title="Apply filters"
                              v-b-tooltip:hover
                              :disabled="userRoles.loading"
                              @click.prevent="handleApplyUserRolesFilters">
                              Apply
                              <b-spinner v-if="userRoles.loading" class="align-middle ml-2" small/>
                              <feather v-else type="filter"></feather>
                            </b-button>
                          </b-col>
                        </b-form-row>

                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="userRoles.fields"
                  :items="userRoles.items"
                  :busy="userRoles.loading"
                  sticky-header="800px"
                  show-empty
                  hover
                  bordered
                  striped
                  :sort-by.sync="userRoles.sort.sortBy"
                  :sort-desc.sync="userRoles.sort.sortDesc"
                  @sort-changed="onUserRolesSortChanged">
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No user roles to show</strong>
                    </div>
                  </template>

                  <template #cell(role_id)="{ item }">
                    <code>{{ item.role_id }}</code>
                  </template>

                  <template #cell(name)="{ item }">
                    <code>{{ item.name }}</code>
                  </template>

                  <template #cell(group)="{ item }">
                    <code v-if="item.group">{{ item.group }}</code>
                    <p title="No group. This role is is not part of any group." v-else>N/A</p>
                  </template>

                  <template #cell(application)="{ item }">
                    <code>{{ item.application }}</code>
                  </template>

                  <template #cell(region)="{ item }">
                    <code>{{ item.region }}</code>
                  </template>

                  <template #cell(actions)="{ item }">
                    <div class="text-center m-0">
                      <b-button
                        size="sm"
                        variant="info"
                        v-if="item.group"
                        v-b-tooltip:hover
                        :title="`This role belongs to ${item.group} group. Click here to manage this user's groups.`"
                        @click="goToUserGroups">
                        <feather type="users"></feather>
                      </b-button>
                      <b-button
                        v-else
                        v-b-tooltip:hover
                        title="Unassign role from user"
                        size="sm"
                        variant="danger"
                        :disabled="item.loading"
                        @click="handleRemoveRole(item)">
                        <b-spinner v-if="item.loading" class="align-middle" small/>
                        <feather v-else type="trash-2"></feather>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->

      <!-- #region Available Roles -->
      <b-col>
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              Available Roles
              <sup
              v-b-tooltip:hover
              title="These are all the roles available that can be assigned to this user.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>

            <div>
              <b-button
                class="mr-4"
                variant="outline-secondary"
                title="Show filters"
                v-b-tooltip:hover
                v-b-toggle.collapse-available-roles-filters>
                Filters
                <feather type="filter"></feather>
              </b-button>

              <b-button
                variant="primary"
                v-b-tooltip:hover
                title="Assign selected roles to this user. You will choose the region for each selected role next."
                :disabled="availableRoles.saveBtn.loading"
                @click="openRegionsModal">
                Save roles
                <b-spinner v-if="availableRoles.saveBtn.loading" class="align-middle ml-2" small/>
                <feather v-else type="save"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <b-collapse id="collapse-available-roles-filters">
                  <b-card class="mb-3" bg-variant="light" no-body>
                    <b-card-body>
                      <b-card-title class="">
                        Filters
                      </b-card-title>

                      <b-form @submit.prevent>
                        <b-form-row>
                          <b-col>
                            <b-form-group id="arf-group-name" label="Name" label-for="arf-name">
                              <b-form-input
                                id="arf-name"
                                placeholder="Enter role name"
                                v-model="availableRoles.filters.name" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-application" label="Application" label-for="arf-application">
                              <b-form-select
                                id="arf-application"
                                v-model="availableRoles.filters.application"
                                :options="availableRolesApplicationFiltersOptions" />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group id="arf-group-category" label="Category" label-for="arf-category">
                              <b-form-select
                                id="arf-category"
                                v-model="availableRoles.filters.category"
                                :options="availableRolesCategoriesFiltersOptions" />
                            </b-form-group>
                          </b-col>

                        </b-form-row>

                        <b-form-row>
                          <b-col class="d-flex justify-content-end">
                            <b-button
                              prevent
                              type="submit"
                              variant="primary"
                              title="Apply filters"
                              v-b-tooltip:hover
                              :disabled="availableRoles.loading"
                              @click.prevent="handleApplyAvailableRolesFilters">
                              Apply
                              <b-spinner v-if="availableRoles.loading" class="align-middle ml-2" small/>
                              <feather v-else type="filter"></feather>
                            </b-button>
                          </b-col>
                        </b-form-row>

                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="availableRoles.fields"
                  :items="availableRoles.paginatedItems.get(availableRoles.filters.page)"
                  :busy="availableRoles.loading"
                  @row-clicked="item => (item.selected = !item.selected)"
                  tbody-tr-class="pointerCursor"
                  show-empty
                  hover
                  bordered
                  striped
                  :sort-by.sync="availableRoles.sort.sortBy"
                  :sort-desc.sync="availableRoles.sort.sortDesc"
                  @sort-changed="onAvailableUserRolesSortChanged">

                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #head(selected)>
                    <div class="text-center">
                      <b-form-checkbox v-if="availableRoles?.paginatedItems.has(availableRoles?.filters.page)"
                        v-model="isAllSelected" @change="selectAllRoles" />
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No available roles to show</strong>
                    </div>
                  </template>

                  <template #cell(name)="{ item }">
                    <code>{{ item.name }}</code>
                  </template>

                  <template #cell(application)="{ item }">
                    <code>{{ item.application }}</code>
                  </template>

                  <template #cell(category)="{ item }">
                    <code>{{ item.category }}</code>
                  </template>

                  <template #cell(selected)="{ item }" >
                    <div class="text-center m-0">
                      <b-form-checkbox
                        :id="`role_${item.id}`"
                        v-model="item.selected"/>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-pagination
                  class="my-0"
                  align="center"
                  :value="this.availableRoles.filters.page"
                  :total-rows="availableRoles.total"
                  :per-page="availableRoles.filters.pageSize"
                  @change="changeAvailableRolesPage">
                </b-pagination>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->
    </b-row>

    <!-- #region Roles Regions -->
    <b-modal
      id="modal-roles-regions"
      ref="modal-roles-regions"
      title="Assign roles to user"
      size="max-1500"
      @hidden="handleRegionsModalHidden">

      <b-container class="p-0" fluid>
        <b-row>
          <b-col>
            <b-alert show variant="info">
              Select below at least one region for each role to be assigned to this user.
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table
              :fields="selectedRolesModal.table.fields"
              :items="selectedRolesModal.table.items"
              :busy="selectedRolesModal.table.loading"
              class="mb-3"
              show-empty
              hover
              outlined
              striped>

              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #empty>
                <div class="text-center my-2">
                  <strong>No selected roles to show</strong>
                </div>
              </template>

              <template #cell(name)="{ item }">
                <code>{{ item.title }}</code>
              </template>

              <template #cell(application)="{ item }">
                <code>{{ item.application }}</code>
              </template>

              <template #cell(category)="{ item }">
                <code>{{ item.category }}</code>
              </template>

              <template #cell(regions)="{ item }">
                <div class="d-flex justify-content-between">
                  <multiselect
                    label="code"
                    track-by="id"
                    v-model="item.regions"
                    :placeholder="item.regions.length ? '' : 'Select one or more regions'"
                    :options="regions"
                    :multiple="true"
                    :showLabels="true"/>

                  <div>
                    <b-button
                      class="ml-2"
                      v-b-tooltip:hover
                      title="Apply to all roles."
                      variant="info"
                      v-if="selectedRolesModal.table.items.length > 1"
                      @click="handleApplyToAllRoles(item.regions)">
                      <feather type="plus-circle"></feather>
                    </b-button>
                  </div>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="secondary"
          :disabled="availableRoles.confirmRolesBtn.loading"
          @click="cancel()">
          Cancel <feather type="x"></feather>
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          :disabled="availableRoles.confirmRolesBtn.loading"
          @click="handleSaveRoles">
          Confirm
          <b-spinner v-if="availableRoles.confirmRolesBtn.loading" class="align-middle ml-2" small/>
          <feather v-else type="check"></feather>
        </b-button>
      </template>
    </b-modal>
    <!-- #endregion -->

    <!-- #region Remove Roles -->
    <b-modal
      id="modal-roles-remove"
      ref="modal-roles-remove"
      title="Remove user's role">
      <p>Are you sure you want to remove the following role from this user?</p>
      <ul>
        <li>Name: <code>{{ removeRoleModal.role.name }}</code></li>
        <li>Application: <code>{{ removeRoleModal.role.application }}</code></li>
        <li>Region: <code>{{ removeRoleModal.role.region }}</code></li>
      </ul>

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="secondary"
          :disabled="removeRoleModal.confirmBtn.loading"
          @click="cancel()">
          Cancel
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          :disabled="removeRoleModal.confirmBtn.loading"
          @click="confirmRemoveRole">
          Confirm
          <b-spinner v-if="removeRoleModal.confirmBtn.loading" class="align-middle ml-2" small/>
          <feather v-else type="trash-2"></feather>
        </b-button>


      </template>
    </b-modal>
    <!-- #endregion -->

  </b-container>
</template>

<style>
/**
 * The following is used on Region selection modal to limit the
 * width of the modal. It is invoked via the `size` prop instead
 * of the `class` attribute: `size="max-1500"`.
 */
div.modal .modal-dialog.modal-max-1500 {
  max-width: 1500px !important;
}

.pointerCursor {
  cursor: pointer !important;
}
</style>
